@use 'src/colors.scss' as colors;

.brewery-card {
  color: colors.$color3;
  background-color: colors.$color1;
  font-size: large;
  font-weight: bold;
  border-radius: 10px;
  padding: 25px;
  margin: 15px;
  width: 50%;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

@media only screen and (max-width: 1200px) {
  .brewery-card {
    width: 80%;
  }
}

.brewery-card-row-1 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.brewery-card-row-2 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.brewery-card-row-3 {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: left;
}

.brewery-card-row-4 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.collapse-text-body {
  height: 30vh;
  width: 100%;
  padding: 20px;
}

.marker {
  height: 12px;
  width: 12px;
  border: 1px solid colors.$color5;
  border-radius: 50%;
  z-index: 1;
  color: colors.$color3;
  background-color: colors.$color1;
}

.marker-text {
  height: auto;
  width: 70px;
  border-radius: 6px;
  z-index: 2;
  color: colors.$color3;
  background-color: colors.$color1;
  padding: 5px;
}

.icon {
  margin-right: 10px;
}

.collapse-text-button,
.btn,
.btn-primary,
.btn-sm {
  color: colors.$color7;
  background-color: colors.$color5;
  :hover,
  :active {
    color: colors.$color5;
    fill: colors.$color4;
  }
}

.invert-chevron,
.restore-chevron {
  transition-duration: 0.55s;
  border-radius: 50%;
  background: colors.$color5;
  fill: colors.$color4;
}

.invert-chevron {
  transform: rotate(180deg);
}

.restore-chevron {
  transform: rotate(0deg);
}

a {
  color: colors.$color4;
}
