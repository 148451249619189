@use 'src/colors.scss' as colors;

$primary: colors.$color5;
$danger: #ad1a1a;
$success: colors.$color7;
$warning: orange;
$info: colors.$color6;

@import '~bootstrap/scss/bootstrap';

.App {
  text-align: center;
  background-color: colors.$color4;
}

.App-header {
  height: 8vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-weight: bold;
  font-size: x-large;
  color: colors.$color1;
  padding: 5px;
}

.App-body {
  min-height: 92vh;
  height: 100%;
  width: 100vw;
  font-weight: normal;
  font-size: medium;
}
